import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Checkbox,
  TableRow,
  TableCell,
  Typography,
  MenuItem,
  Stack,
} from "@mui/material";
// redux
import { useDispatch, useSelector } from "../../redux/store";
// hooks
import useLocales from "../../hooks/useLocales";
// utils
import axios from "../../utils/axios";
// components
import Label from "../../components/Label";
import Iconify from "../../components/Iconify";
import { TableMoreMenu } from "../../components/table";

// ----------------------------------------------------------------------

OrganisationMemberTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function OrganisationMemberTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  deleteLoading
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const { type, user } = row;
  const [fetchedUser, setFetchedUser] = useState(null);

  const { userInOrganisation, user: connectedUser } = useSelector((state) => state.user);

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const distantUser = await axios.get(`${user["@id"]}`);
        console.log({distantUser:distantUser.data});
        
        setFetchedUser(distantUser.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [user?.['@id']]);

  const canEditRow = useMemo(() => {
    return userInOrganisation?.type === 'ADMIN' && connectedUser?.id !== user?.id
  }, [row])

  return (
    <TableRow hover selected={selected}>
      <TableCell sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          alt={user.firstname}
          src={fetchedUser?.image?.content_url}
          sx={{ mr: 2 }}
        />
        <Stack direction="column">
          <Typography variant="subtitle2" noWrap>
            {user.firstname} {user.lastname}
          </Typography>
          <Typography variant="body2" noWrap>
            {user.email}
          </Typography>
          {fetchedUser?.user_in_organisations.filter(
            (_userInOrganisations) =>
              _userInOrganisations.organisation.id ===
              userInOrganisation.organisation.id &&
              _userInOrganisations.accepted === false
          ).length > 0 && (
              <Label variant={"outlined"} color={"primary"} size="small">
                {translate('snackbar.inviteSent')}
              </Label>
            )}
        </Stack>
      </TableCell>

      <TableCell align="left">
        <Stack direction="row" spacing={1}>
          {fetchedUser &&
            fetchedUser?.user_in_teams
              .filter(
                (_user_in_teams) =>
                  _user_in_teams.team.organisation.id ===
                  userInOrganisation.organisation.id
              )
              .map((team) => (

                team.team?.is_own_team ? <></> :
                  <Label
                    variant={theme.palette.mode === "light" ? "ghost" : "filled"}
                    color={"info"}
                  >
                    {team.team.name}
                  </Label>
              ))}
        </Stack>
      </TableCell>

      <TableCell align="center">
        <Label
          variant={theme.palette.mode === "light" ? "ghost" : "filled"}
          color={
            (type === "MEMBER" && "success") || (type === "ADMIN" && "error")
          }
        >
          {translate(type)}
        </Label>
      </TableCell>

      <TableCell align="center">
        {fetchedUser && (
          <Label
            variant={theme.palette.mode === "light" ? "ghost" : "filled"}
            color={
              (fetchedUser && fetchedUser.activated === false && "error") ||
              "success"
            }
          >
            {fetchedUser.activated && translate("settings.userActivated")}
            {!fetchedUser.activated && translate("settings.userNotActivated")}
          </Label>
        )}
      </TableCell>


      {
        canEditRow && <TableCell align="right">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <MenuItem
                  disabled={deleteLoading}
                  onClick={() => {
                    onDeleteRow();
                    handleCloseMenu();
                  }}
                  sx={{ color: "error.main" }}
                >
                  <Iconify icon={"eva:trash-2-outline"} />
                  {translate('nav.delete')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onEditRow();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={"eva:edit-fill"} />
                  {translate('gridOptions.update')}
                </MenuItem>
              </>
            }
          />
        </TableCell>
      }

    </TableRow>
  );
}
