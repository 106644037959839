import { ArrowBack, AssessmentOutlined, DataSaverOn, DriveFileRenameOutline, Groups2Outlined, HelpOutline, MoreVert, NoteOutlined, SaveAltOutlined } from '@mui/icons-material'
import { Alert, Box, Button, Chip, CircularProgress, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { get, isEmpty, isNull, uniqBy, upperCase } from 'lodash'
import React, { useEffect } from 'react'
import { useMemo } from 'react'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import CompareModal from 'src/components/dashboard/CompareModal'
import DashboardMenu from 'src/components/dashboard/DashboardMenu'
import FilterModal from 'src/components/dashboard/FilterModal'
import ManageExamModal from 'src/components/dashboard/ManageExamModal'
import ManageUserModal from 'src/components/dashboard/ManageUserModal'
import ShareModal from 'src/components/dashboard/ShareModal'
import Error from 'src/components/global/Error'
import useLocales from 'src/hooks/useLocales'
import { getExamensSuccess, getExamenSuccess, getExamVetCase, getExamVetCaseV2 } from 'src/redux/slices/examen'
import { getHorseExams } from 'src/redux/slices/horse'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { columns, injectI18N } from 'src/utils/dashboard'
import { vetCaseExamsColumns, vetCaseExamsFilters } from 'src/utils/examDashboard'
import Fade from '@mui/material/Fade';
import DownloadReportModal from 'src/components/dashboard/DownloadReportModal'
import { setBreadcrumb } from 'src/redux/slices/display'
import { ExamExtractor } from 'src/utils/ExamExtractor'
import { Examen } from 'src/classes/Examen'
import { useSnackbar } from 'notistack'
import { useVetCaseDownload } from 'src/hooks/useVetCaseDownload'
import UpdateCaseModal from 'src/components/dashboard/UpdateCaseModal'
import Iconify from 'src/components/Iconify'
import { useLinkDownload } from 'src/hooks/useLinkDownload'

function ColumnExamView({ item, onBack, refreshVetCase }) {
  const { horse } = useSelector(state => state.horse)
  const [shareModal, setShareModal] = useState(false)
  const [compareModal, setCompareModal] = useState(false)
  const [open, setOpen] = useState(false)
  const [openManageUser, setOpenManageUser] = useState(false)
  const [openManageExam, setOpenManageExam] = useState(false)
  const [downloadModal, setDownloadModal] = useState(false)
  const [updateCaseModal, setUpdateCaseModal] = useState(false)
  const { userInOrganisation } = useSelector((state) => state.user);
  const [horseAllExams, setHorseAllExams] = useState([])
  const navigate = useNavigate()
  const { translate, dataGridLocales } = useLocales()
  const updateCaseMutation = useMutation(({ data, id }) => Examen.updateCase(data, id))
  const { enqueueSnackbar } = useSnackbar();

  const [examDateSort, setExamDateSort] = useState(null)

  const { downloadExamData, loading } = useVetCaseDownload()

  const { downloadByLink, isDownloading } = useLinkDownload()

  const downloadVetCaseLabels = useMutation((data) => Examen.downloadVetCaseLabels(item?.id))


  const dispatch = useDispatch()

  const queryParams = useMemo(() => {
    let params = []
    if (examDateSort) {
      params.push(`order[e.creationDate]=${upperCase(examDateSort)}`)
    }
    return params
  }, [examDateSort])

  //Prepare query
  const { data, isLoading, isError, refetch } = useQuery(
    ['vet_case_exams', item?.id, queryParams],
    () => getExamVetCaseV2(item?.id, queryParams),
    {
      enabled: !isNull(item?.id),
      staleTime: 10 * 60 * 1000,
    }
  )
  //
  useEffect(() => {
    window.addEventListener('popstate', (event) => {
      // The popstate event is fired each time when the current history entry changes.

      // onBack()
      dispatch(setBreadcrumb([
        {
          text: translate('nav.allHorses'),
          link: '/horses',
          isDisabled: false
        },

        {
          text: get(horse, 'name', ''),
          link: `/horses/${horse.external_id}/list-view`,
          isDisabled: true
        },
      ]))
    }, false);

    dispatch(setBreadcrumb([
      {
        text: translate('nav.allHorses'),
        link: '/horses',
        isDisabled: false,
        sectionTitle: translate('')

      },

      {
        text: get(horse, 'name', ''),
        link: `/horses/${horse.external_id}/list-view`,
        isDisabled: false,
        sectionTitle: translate('grid.horseName')

      },
      {
        text: get(item, 'case_number', translate('general.unamed_folder')),
        link: '',
        isDisabled: true,
        sectionTitle: translate('nav.folder')

      },
    ]))

    return () => window.removeEventListener('popstate', () => { console.log('event removed') })

  }, [])


  //get Exams query params

  const getOrgQueryParam = useMemo(() => {
    if (userInOrganisation?.restricted) {
      return [`organisation=${userInOrganisation?.id}`]
    } else {
      return [`user_in_organisation=${userInOrganisation?.id}`]
    }
  }, [userInOrganisation])

  const { data: horseExams, isLoading: horseExamsLoading } = useQuery(

    ['horse', item?.horse.external_id],
    () => getHorseExams(item?.horse.external_id, undefined, getOrgQueryParam),
    {
      enabled: !isEmpty(item),
      onSuccess: ({ data }) => {

        let horseAllExams = get(data, 'hydra:member', [])
        setHorseAllExams(horseAllExams)

      }
    }
  )



  const publishedRepport = useMemo(() => {
    return item.reports.filter(e => e?.published)
  }, [item])


  const _exams = get(data, 'data.hydra:member', [])


  const mappedExams = useMemo(() => {
    if (isEmpty(_exams)) return []
    return uniqBy(_exams?.map(e => {
      return { ...e.exam, relation_id: e.id, case_id: item?.id, ecg_reviews: e?.ecg_reviews ?? [] }

    }), '@id')
  }, [_exams])

  const isEnabledSuiviLongitudinal = useMemo(() => {
    return mappedExams.every(exam => get(exam, '@type') === "ExamEquimetre")
  }, [mappedExams])

  //HandleCellClick
  const handleCellClick = (param) => {
    if (param.field !== 'actions') {

      dispatch(getExamenSuccess(null))

      let horse = ExamExtractor.getHorseId(param.row)
      let externalId = param.row.external_id
      let examType = ExamExtractor.getExamType(param.row)

      if (examType === 'Equisym') {
        navigate(PATH_DASHBOARD.horses.examenView(
          horse,
          externalId,
          item?.id,
        ))
      }

      if (examType === 'Equimetre') {
        navigate(PATH_DASHBOARD.horses.examenEquimetreView(
          horse,
          externalId,
          item?.id,
        ))
      }

    }
  }

  const updateCase = (data, id) => {

    updateCaseMutation.mutateAsync({ data, id })
      .then(res => {
        enqueueSnackbar(translate('snackbar.updateSuccess'), {
          variant: 'success'
        })
        refreshVetCase()
      })
      .catch(err => {
        enqueueSnackbar(translate('general.error'), {
          variant: 'error'
        })
      })
      .finally(() => {
        setUpdateCaseModal(false)

      })
  }

  const isSelectedVetCaseHasEquisymExams = useMemo(() => {

    let exams = item.exam_in_vet_cases?.map(e => e?.exam)
    if (isEmpty(exams)) return false
    return exams.some(e => get(e, '@type') === "ExamEquisym")
  }, [item])

  const isUserInvited = item?.current_user_invited

  const handleSortModelChange = (sortModel) => {
    setExamDateSort(get(sortModel, '[0].sort', null))
  };

  const downloadEcgLabels = async () => {
    try {
      let { data } = await downloadVetCaseLabels.mutateAsync()

      if ('download_url' in data) {
        await downloadByLink(data.download_url, 'snackbar.labelsExported')
      }

    } catch (error) {
      enqueueSnackbar(translate('general.error'), {
        variant: 'error'
      })
    }
  }

  if (isLoading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' marginTop={3}>
        <CircularProgress />
      </Box>
    )
  }

  if (isError) {
    return <Error onClick={onBack} buttonText={'nav.backToPreviousScreen'} />
  }

  return (
    <Box marginTop={5}>
      {/* Dashboard Header */}
      {
        isUserInvited && <Alert severity='info' sx={{ mb: 2 }}>{translate('vetcase.limitedAccess')}</Alert>
      }

      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent='space-between'
        flexWrap={'wrap'}
        alignItems={{ md: 'center', xs: 'flex-start' }}
        marginBottom={3}
        gap={1}
      >
        <Button startIcon={<ArrowBack />} onClick={onBack} size='small' fullWidth={false} variant='text'>{translate('nav.folders')}</Button>
        <Box display={'flex'} >
          <DashboardMenu
            options={{
              showCompare: false,
              showExport: false,
              showFilter: false,
              showShare: false,
              showManageUsers: true,
              showMultiOptions: false,
              showManageExams: true,
              showLongitude: !isEnabledSuiviLongitudinal,
              showDownloadRepport: !isEmpty(publishedRepport),
              showDownloadData: isSelectedVetCaseHasEquisymExams,
              showUpdateCase: true,
              showDownloadExportLabels: true,
            }}
            disableOptions={isUserInvited}
            labelsDownloading={isDownloading || downloadVetCaseLabels.isLoading}
            downloadData={loading}
            onCompare={() => setCompareModal(true)}
            onShare={() => setShareModal(true)}
            onFilter={() => setOpen(true)}
            onManageUser={() => setOpenManageUser(true)}
            onManageExam={() => setOpenManageExam(true)}
            onFollowLongitude={() => navigate(PATH_DASHBOARD.horses.followLongitude(item?.horse?.external_id, item?.id))}
            onDownloadRepport={() => setDownloadModal(true)}
            onEditCase={() => setUpdateCaseModal(true)}
            onDownloadData={() => downloadExamData(item)}
            onExportLabels={downloadEcgLabels}
          ></DashboardMenu>



        </Box>




        {/* Modals Section */}

        <UpdateCaseModal
          open={updateCaseModal}
          handleClose={() => setUpdateCaseModal(false)}
          onCancel={() => setUpdateCaseModal(false)}
          item={item}
          onUpdate={updateCase}
          loading={updateCaseMutation.isLoading}
        />

        <ManageUserModal
          onChangeTeamClick={() => {
            setOpenManageUser(false)
            setUpdateCaseModal(true)
          }}
          refreshVetCase={refreshVetCase} vetCase={item} open={openManageUser} handleClose={() => setOpenManageUser(false)} />
        <ManageExamModal refreshVetCase={() => {
          refetch()
          refreshVetCase()
        }} horseExams={horseAllExams} open={openManageExam} handleClose={() => setOpenManageExam(false)} exams={_exams} vetCase={item} loading={horseExamsLoading} />

        <DownloadReportModal
          open={downloadModal}
          handleClose={() => setDownloadModal(false)}
          onCancel={() => setDownloadModal(false)}
          title={translate('gridOptions.downloadRepport')}
          items={publishedRepport}
        />
      </Stack>



      {/* Info Section */}

      <Stack
        direction={{ xs: "column", md: "row" }}
        gap={1}
        marginBottom={1}
        flexWrap
      >
        <Tooltip title={translate('grid.userFullname')} arrow>
          <Chip icon={<Iconify icon='fluent:people-team-28-regular' width={18} height={18} />} label={
            isUserInvited ? translate('general.invited') : get(item, 'team.name', ' - ')} />
        </Tooltip>
        <Tooltip title={translate('grid.reason')} arrow>
          <Chip icon={<Iconify icon='tabler:device-ipad-question' width={18} height={18} />} label={get(item, 'reason') || '-'} />
        </Tooltip>
        {
          get(item, 'auto_add_future_horse_exams') &&
          <Chip icon={<DataSaverOn />} label={translate('autoAddExamHint', { horse: get(horse, 'name') })} />

        }


      </Stack>

      <Stack
        direction={{ xs: "column", md: "row" }}
        gap={1}
        marginBottom={3}
        flexWrap
      >
        <Tooltip title={translate('grid.note')} arrow>
          <Chip icon={<Iconify icon='mdi-light:note-text' width={18} height={18} />} label={get(item, 'notes') || '-'} />
        </Tooltip>
      </Stack>

      <Typography fontSize={18} fontWeight={600}>{translate('general.exams')}</Typography>
      <DataGrid
        rows={mappedExams}
        columns={injectI18N(vetCaseExamsColumns, 'headerName', translate)}
        getRowId={(row) => row['@id']}
        autoHeight
        onCellClick={(params) => handleCellClick(params)}
        rowsPerPageOptions={[5, 25, 50]}
        localeText={dataGridLocales()}
        sortModel={[
          {
            field: 'createdAt',
            sort: examDateSort
          }
        ]}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
      />
    </Box>
  )
}


export default ColumnExamView