import { useState, useEffect, memo, useMemo, useCallback } from "react";

import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import AnnotationsFactory from "highcharts/modules/annotations";

// config
import { HEADER } from "../../config";
// @mui
import { Box } from "@mui/material";
//components
import { LinesMode } from "./EqsWindow";
import { cloneDeep, get, isEmpty } from "lodash";
import { Condition } from "src/classes/Condition";
import useLocales from "src/hooks/useLocales";
import { Visualizer } from "src/classes/Visualizer";
import { useRef } from "react";
import { useSelector } from "react-redux";

highchartsMore(Highcharts);
AnnotationsFactory(Highcharts);

const colors = {
  chart: {
    strideLeft: "rgba(249, 170, 245, 0.43)",
    strideLeftLabel: "rgb(156, 84, 152)",
    strideRight: "rgba(130, 207, 249, 0.43)",
    strideRightLabel: "rgb(71, 127, 157)",
    strideOther: "rgba(192, 192, 192, 0.25)",
  },
};

// ----------------------------------------------------------------------

function EqsChart({
  dataSet,
  linesMode,
  variablesMode,
  fill,
  title,
  isComparaison,
  selectedConditions,
  showChartDetails,
  componentsHeight
}) {

  const { translate } = useLocales()
  const chart = useRef(null)
  const [chartOptions, setChartOptions] = useState({});

  const { user } = useSelector(state => state.user)

  useEffect(() => {

    let viz = new Visualizer(dataSet, linesMode, variablesMode, selectedConditions, showChartDetails)
    viz.init()
    setChartOptions({
      chart: {
        height: componentsHeight,
        spacingTop: 5,
        spacingBottom: 5,
        backgroundColor: null,
        animation: false,
        style: {
          fontFamily: "Public Sans",
        },
      },
      stockTools: {
        gui: {
          enabled: false, // disable the built-in toolbar
        },
      },
      scrollbar: {
        liveRedraw: false
      },
      annotations: showChartDetails ? viz.getAnnotations() : [],
      plotOptions: {
        series: {
          animation: false,
          fillOpacity: 0.4,
          marker: {
            enabled: false,
          },
          states: {
            inactive: {
              enabled: false,
            },
            hover: {
              enabled: false,
            },
          },
        },
      },
      title: {
        text: translate(title),
        align: "left",
        margin: 0,
        style: {
          fontSize: "16px",
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      xAxis: {
        crosshair: false,
        tickLength: 0,
        tickInterval: 50,
        animation: false,
        lineWidth: 1,
        labels: {
          format: "{value}",
          style: {
            fontSize: "8px",
          },
        },
        min: 0,
        max: 150,
        endOnTick: true,
        //---------------------------------------------//
        // Plot Bands : Les bandes colorées sur xAxis 
        //---------------------------------------------//
        plotBands: [
          {
            from: get(dataSet, '[0].stridesRange.left.start', 0),
            to: get(dataSet, '[0].stridesRange.left.end', 0),
            color: colors.chart.strideLeft,
            zIndex: 1,
            label: {
              text: get(dataSet, '[0].stridesRange.left.title', '') && translate('examEnums.' + get(dataSet, '[0].stridesRange.left.title', '')),
              style: {
                color: colors.chart.strideLeftLabel,
                fontWeight: "bold",
              },
            },
          },
          {
            from: get(dataSet, '[0].stridesRange.right.start', 0),
            to: get(dataSet, '[0].stridesRange.right.end', 0),
            color: colors.chart.strideRight,
            zIndex: 1,
            label: {
              text: get(dataSet, '[0].stridesRange.right.title', '') && translate('examEnums.' + get(dataSet, '[0].stridesRange.right.title', '')),
              style: {
                color: colors.chart.strideRightLabel,
                fontWeight: "bold",
              },
            },
          },
          {
            from: get(dataSet, '[0].stridesRange.other.start', 0),
            to: get(dataSet, '[0].stridesRange.other.end', 0),
            color: colors.chart.strideOther,
            zIndex: 1,

            label: {
              text: get(dataSet, '[0].stridesRange.other.title', '') && translate('examEnums.' + get(dataSet, '[0].stridesRange.other.title', '')),
            },
          },
        ],
      },
      yAxis: [
        {
          title: "",
          crosshair: false,
          gridLineColor: "#e4e8eb",
          animation: false,
          tickInterval: 1,
          // min: Condition.getMinMax(dataSet).min,
          // max: Condition.getMinMax(dataSet).max,
          zIndex: 100,
          endOnTick: false,
          labels: {
            zIndex: 100,
            format: "{value}",
            style: {
              fontSize: "8px",
            },
          },
          opposite: false,
        },
      ],
      series: viz.getData(),
    })

  }, [linesMode, variablesMode, dataSet, selectedConditions, componentsHeight,user?.lang])


  return (


    <HighchartsReact
      ref={chart}
      highcharts={Highcharts}
      options={chartOptions}
      immutable={true}


    />

  );
}
export default memo(EqsChart)
EqsChart.defaultProps = {
  title: "",
  showChartDetails: true,
  componentsHeight: '190px'
}